<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<div>
				<!-- 时间筛选 -->
				<div class="filter-item">
					<label class="label">申请时间:</label>
					<el-date-picker v-model="applyTime" type="datetimerange" range-separator="～" start-placeholder="开始日期"
					end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">联系电话:</label>
					<el-input class="input-item" clearable style="margin-left:5px;width:250px" v-model="concatPhone"
					@keyup.enter.native="search" placeholder="请输入联系电话"></el-input>
				</div>
				<!-- button -->
				<div class="filter-item">
					<el-button type="primary" style="width:100px;margin-right:20px;" @click="search">查询</el-button>
				</div>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column prop="createTime" label="申请时间"></el-table-column>
				<el-table-column prop="cooperationValue" label="合作方向"></el-table-column>
				<el-table-column prop="name" label="联系人"></el-table-column>
				<el-table-column prop="phone" label="联系电话"></el-table-column>
				<el-table-column prop="weChat" label="微信号"></el-table-column>
				<el-table-column prop="conText" label="留言"></el-table-column>
				<el-table-column prop="categoryName" label="类目"></el-table-column>
				<el-table-column label="操作" width="300">
					<template slot-scope="scope">
						<button-permissions :datas="'handleOver'" v-if="scope.row.status==1">
							<el-button disabled="false" type="info">已确认</el-button>
						</button-permissions>
						<button-permissions :datas="'handleConcat'" v-if="scope.row.status==0">
							<el-button type="primary" @click="handleConcat(scope.row)">合作确认</el-button>
							<!-- <div class="table-button" @click="handleConcat(scope.row)">合作确认</div> -->
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="searchData.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="searchData.pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="total"></el-pagination>
		</div>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" center>
			<div style="text-align: center;padding: 30px 0;font-size: 22px;">你确定已经联系此人了吗？</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 80px;" @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" style="margin-left: 40px;width: 80px;" @click="sureContact">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import {
		centrefindAll,
		updateStatus
	} from '@/api/setting'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				applyTime: '', //时间
				concatPhone: '',
				searchData: {
					pageNo: 1,
					pageSize: 20
				},
				total: 0,
				loading: false,
				tableData: [],
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				dialogVisible: false,
				currentItem:null,
			}
		},
		async beforeMount() {
			this.getList();
		},
		computed: {

		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true
				try {

					let data = {
						pageNo: this.searchData.pageNo,
						pageSize: this.searchData.pageSize,
						phone: this.concatPhone
					}
					if (this.applyTime) {
						data.begTime = this.applyTime[0];
						data.endTime = this.applyTime[1];
					} else {
						data.begTime = '';
						data.endTime = '';
					}
					let result = await centrefindAll(data);
					result.data.records.map(item => {
						item.cooperationValue = item.type == 1 ? '我有资源，我想入驻' : '我有团队，我想入驻';
						return item;
					})
					this.tableData = result.data.records;
					this.total = result.data.total;
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			//搜索
			search() {
				this.searchData.pageNo = 1
				this.getList()
			},
			//去联系
			handleConcat(row) {
				this.currentItem = row;
				this.dialogVisible = true;
				console.log("----", row)

			},

			// 切换显示条数
			handleSizeChange(val) {
				this.searchData.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.searchData.pageNo = val;
				this.getList();
			},
			handleClose() {
				this.dialogVisible = false;
			},
			async sureContact(){
				
				let data = {
					id:this.currentItem.id
				};
				let res = await updateStatus(data);
				if(res.success){
					this.$message({
						showClose: true,
						message:'合作确认成功',
						type:'success',
					})
					this.getList();
				}else{
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
				this.dialogVisible = false;
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.input-item {
		margin-left: 5px;
	}

	.table-button {
		color: #409eff;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}
	}

	/deep/.el-table__fixed-body-wrapper {
		background: #ffffff;
	}

	/deep/.el-table__fixed-header-wrapper {
		background: #f8f8f9;
	}
</style>
